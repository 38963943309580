* {
  box-sizing: border-box;
}
.App {
/*   text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('images/header_3dfmf-l-32.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #121212;
  color: #f2f2f2;
  line-height: 1.6;
}

main {
/*   padding:  0 1em; */
}

a:link, a:visited {
  color: #f2f2f2;
  text-decoration: none;
}
a:hover, a:active {
  color: magenta;
  text-decoration: underline;
}

img,figure {
  width:100%;
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  text-align: center;
}


button:hover {
    box-shadow: -3px 3px #5b54db;
    -webkit-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
}
button:active {
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
}

section {
  padding-bottom: 15vh;
  padding-top: 5vh;
}

ul {
  list-style: none;
}

nav {
  position: sticky;
  top: 0;
  background-color: #121212;
  min-height: 5vh;
  z-index: 1;
}

nav ul {
  min-height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0;
}

hr {
  border-color: #404040;
}

figcaption {
  text-align: right;
  font-size: .8rem;
}

footer {
  padding: 10em 0 1em 0;
}

footer h4 {
  margin: 3em 0;
}

footer ul {
  margin: 0;
  padding: 0;
}

footer .grid2 {
  justify-items:center;
}

iframe {
  width: 100%;
  height: 300px;
}

.emailSentPopUp {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background-color: #282c34;

}
.emailSentPopUp article {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.emailSentPopUp article span {
  position: absolute;
  top: .5em;
  right: 1em;
  font-size: 1.5em;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.flex3 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.flex3 > img {
  flex: 1 1 250px;
  width: 250px;
  margin: 10px;
}
.flex3 > figure{
  flex: 1 1 100%;
  margin: 10px;
}

.contentContainer {
  max-width: 1200px;
  margin: 0 auto;
}

.smallText {
  font-size: .8rem;
  text-align: center;
}

.spanAcrossGrid {
  grid-column: 1/-1;
  text-align: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  box-shadow: -3px 3px #000;
}
.video-container embed, .video-container iframe, .video-container object {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


#applications h3, footer h2 {
  text-align: left;
}
#applications  figure {
  max-width: 300px;
  margin: 0 auto;
/*   width: 150px; */
}
#blueFutures {
  padding-top: 0;
  background-image: url('images/backgrounds/img-9C5XEGSc6LGZSOMeWqANw.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#evrythingispos {
  background-image: url('images/backgrounds/img-FircI4ugstx7VMakYCPbc.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#vivacasting {
  background-image: url('images/backgrounds/img-uDGN5MCNExi4VchYI7nIr.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#about {
  background-image: url('images/backgrounds/img-IUSiDa0KybQy8cZRwkZQA.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#contact {
  background-image: url('images/backgrounds/img-apzvAPUicE5L5tvazOUi8.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#applications {
  background-image: url('images/backgrounds/img-DfqKo8smmSTFHwVXMPc49.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#services {
  background-image: url('images/backgrounds/img-G3sjN6CXo6sp7zR6dpXRT.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#threeD {
  background-image: url('images/backgrounds/img-iFmGFoTrcZxkCCu0rg3xF.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.flexColumn input,.flexColumn textarea, .flexColumn button,.flexCont input,.flexCont textarea, .flexCont button{
  width: 50%;
  min-width: 100px;
/*   max-width: 300px; */
padding: 10px;
/*   margin: 10px; */
border-radius: 5px;
border: 1px solid #000;
}

.flexCont  {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.navMob {
  display: none;
}

  .navMob ul.active {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: #121212;
    font-size: 2em;
  }

@media (max-width: 1200px) {
  section {
    margin: 0 1em;
  }
}

@media (max-width:800px){
  .navDesk {
    display: none;
  }
  .navMob {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .navMob span {
    rotate: 90deg;
    margin-right: 2em;
  }
  .navMob ul {
    display: none;
  }

/*   .navMob ul.active {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: #121212;
    font-size: 2em;
  } */
}

/* change grid2 to one column for smaller than 700px screens */
@media (max-width: 700px) {
  .grid2 {
    grid-template-columns: 1fr;
  }
  footer .grid2 {
    display: block;
    text-align: center;
  }
  footer  .grid2  h2 {
    text-align: center;
  }

}

/* change grid3 to one column for smaller than 550px screens */
@media (max-width: 550px) {
  .grid3 {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 750px) {
  .flexCont {
    flex-direction: column;
  }
}


